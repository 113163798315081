import { LayoutProps } from '@kronoss/bricks/Layout/Layout.props';
import { FC } from 'react';
import Header from '@kronoss/bricks/Header/Header';
import Footer from '@kronoss/bricks/Footer/Footer';
import styles from './Layout.module.scss';

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <div className={styles.Layout}>
      <Header />
      <div className={styles.Main}>{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
