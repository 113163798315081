import toast from 'react-hot-toast';
import { ToastProps } from '@kronoss/components/Toast/Toast.props';
import ToastComponent from '@kronoss/components/Toast/Components/ToastComponent/ToastComponent';

const Toast = ({ type, label, message }: ToastProps) => {
  return toast(
    (t) => <ToastComponent type={type} label={label} message={message} />,
    { id: message, position: 'top-right' },
  );
};

export default Toast;
