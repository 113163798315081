import styles from './MobileHeader.module.scss';
import Link from 'next/link';
import cn from 'classnames';
import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Select from '@kronoss/components/Select/Select';
import { availableLanguages } from '@kronoss/bricks/Header/Header';
import KronossLogoIcon from '@kronoss/icons/KronossLogoIcon';
import { contactPageRoute, homePageRoute } from '@kronoss/utils/routes';
import { SelectChangeEvent } from '@mui/material';
import { useRouter } from 'next/router';
import Button from '@kronoss/components/Button/Button';
import SandwichLineIcon from '@kronoss/icons/SandwichLineIcon';
import XMarkIcon from '@kronoss/icons/XMarkIcon';

function MobileHeader() {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { t, lang } = useTranslation('header');

  const handleLangChange = (event: SelectChangeEvent<string | number>) => {
    const selectedLang = event.target.value as string;
    router.push(router.pathname, router.asPath, { locale: selectedLang });
  };
  return (
    <div className={cn(styles.MobileHeaderContainer)}>
      <div
        className={cn(
          styles.MobileHeaderWrapper,
          isOpen && styles.OpenMenu,
          router.pathname === '/' && styles.Background,
        )}
      >
        <Link
          href="/"
          className={styles.MobileLogo}
          aria-label={'Kronoss logo'}
        >
          <KronossLogoIcon />
        </Link>
        <div className={styles.ButtonsWrapper}>
          <div
            className={styles.SandwichButton}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? <XMarkIcon /> : <SandwichLineIcon />}
          </div>
        </div>
      </div>
      <div className={cn(styles.ModalContainer, isOpen && styles.Open)}>
        <Link
          href={homePageRoute + '#how-it-works'}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={styles.NavLink}
          aria-label={t('nav.pricing')}
        >
          {t('nav.howItWorks')}
        </Link>
        <Link
          href={homePageRoute + '#benefits'}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={styles.NavLink}
          aria-label={t('nav.pricing')}
        >
          {t('nav.benefits')}
        </Link>
        {/*<Link href={homePageRoute + '#reviews'}>Reviews</Link>*/}
        <Link
          href={contactPageRoute}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={styles.NavLink}
          aria-label={t('nav.pricing')}
        >
          {t('nav.contact')}
        </Link>
        <Link
          href={homePageRoute + '#faq'}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          className={styles.NavLink}
          aria-label={t('nav.pricing')}
        >
          {t('nav.faq')}
        </Link>
        <div className={styles.ActionsWrapper}>
          <Select
            fullWidth={false}
            value={lang}
            options={availableLanguages}
            onChange={handleLangChange}
          />
          <Link
            href={'/contact'}
            className={styles.ActionLink}
            aria-label={t('actions.bookDemo')}
          >
            <Button
              aria-label={t('actions.bookDemo')}
              className={styles.BookDemoButton}
            >
              {t('actions.bookDemo')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
