import { FooterProps } from '@kronoss/bricks/Footer/Footer.props';
import { FC } from 'react';
import SubscribeInput from '@kronoss/components/SubscribeInput/SubscribeInput';
import styles from './Footer.module.scss';
import { Container } from '@mui/material';
import Link from 'next/link';
import {
  contactPageRoute,
  homePageRoute,
  privacyPolicyPageRoute,
  termsAndConditionsPageRoute,
} from '@kronoss/utils/routes';
import KronossWhiteLogoIcon from '@kronoss/icons/KronossWhiteLogoIcon';
import useTranslation from 'next-translate/useTranslation';
import dayjs from 'dayjs';

const Footer: FC<FooterProps> = () => {
  const { t } = useTranslation('footer');
  return (
    <footer className={styles.FooterWrapper}>
      <Container>
        <div className={styles.Footer}>
          <div className={styles.LogoAction}>
            <Link
              href={homePageRoute}
              className={styles.LogoWrapper}
              aria-label={'Home'}
            >
              <KronossWhiteLogoIcon />
            </Link>
            <SubscribeInput />
          </div>
          <div className={styles.Links}>
            <Link
              href={homePageRoute + '#how-it-works'}
              aria-label={t('nav.howItWorks')}
            >
              {t('nav.howItWorks')}
            </Link>
            <Link
              aria-label={t('nav.benefits')}
              href={homePageRoute + '#benefits'}
            >
              {t('nav.benefits')}
            </Link>
            {/*<Link href={homePageRoute + '#reviews'}>Reviews</Link>*/}
            <Link href={contactPageRoute} aria-label={t('nav.contact')}>
              {t('nav.contact')}
            </Link>
            <Link href={homePageRoute + '#faq'} aria-label={t('nav.faq')}>
              {t('nav.faq')}
            </Link>
          </div>
          <div className={styles.Divider} />
          <div className={styles.Copyright}>
            <div className={styles.CopyrightText}>
              © {dayjs().year().toString()} Kronoss. {t('copyright')}
            </div>
            <div className={styles.Links}>
              <div>{t('policies.cookies')}</div>
              <Link
                href={privacyPolicyPageRoute}
                aria-label={t('policies.privacyPolicy')}
              >
                {t('policies.privacyPolicy')}
              </Link>
              <Link
                href={termsAndConditionsPageRoute}
                aria-label={t('policies.terms')}
              >
                {t('policies.terms')}
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
