import { HeaderProps } from '@kronoss/bricks/Header/Header.props';
import { FC, useEffect, useState } from 'react';
import styles from './Header.module.scss';
import KronossLogoIcon from '@kronoss/icons/KronossLogoIcon';
import { Container, SelectChangeEvent } from '@mui/material';
import Link from 'next/link';
import { contactPageRoute, homePageRoute } from '@kronoss/utils/routes';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import Button from '@kronoss/components/Button/Button';
import Select from '@kronoss/components/Select/Select';
import cn from 'classnames';
import MobileHeader from '@kronoss/bricks/Header/components/MobileHeader/MobileHeader';

export const availableLanguages = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Español' },
];

const Header: FC<HeaderProps> = () => {
  const router = useRouter();
  const { t, lang } = useTranslation('header');
  const [isMobile, setIsMobile] = useState<boolean>(false);

  // Handle resizing for mobile layout
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsMobile(screenWidth <= 1023);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleLangChange = (event: SelectChangeEvent<string | number>) => {
    const selectedLang = event.target.value as string;
    router.push(router.pathname, router.asPath, { locale: selectedLang });
  };
  return isMobile ? (
    <MobileHeader />
  ) : (
    <header
      className={cn(
        styles.Header,
        router.pathname === '/' && styles.Background,
      )}
    >
      <Container>
        <div className={styles.HeaderInner}>
          <Link
            aria-label={'home'}
            href={homePageRoute}
            className={styles.LogoWrapper}
          >
            <KronossLogoIcon />
          </Link>
          <nav className={styles.Nav}>
            <Link
              href={homePageRoute + '#how-it-works'}
              aria-label={t('nav.howItWorks')}
            >
              {t('nav.howItWorks')}
            </Link>
            <Link
              aria-label={t('nav.benefits')}
              href={homePageRoute + '#benefits'}
            >
              {t('nav.benefits')}
            </Link>
            {/*<Link href={homePageRoute + '#reviews'}>Reviews</Link>*/}
            <Link aria-label={t('nav.contact')} href={contactPageRoute}>
              {t('nav.contact')}
            </Link>
            <Link aria-label={t('nav.faq')} href={homePageRoute + '#faq'}>
              {t('nav.faq')}
            </Link>
          </nav>
          <div className={styles.Actions}>
            <Select
              fullWidth={false}
              value={lang}
              options={availableLanguages}
              onChange={handleLangChange}
            />
            <Link href={contactPageRoute} aria-label={t('actions.bookDemo')}>
              <Button aria-label={t('actions.bookDemo')}>
                {t('actions.bookDemo')}
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
