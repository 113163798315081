const KronossLogoIcon = () => {
  return (
    <svg
      width="141"
      height="40"
      viewBox="0 0 141 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="6.77057" fill="#F3F4F6" />
      <path
        d="M21.981 18.9917H27.981C31.7522 18.9917 33.6378 18.9917 34.8094 20.1633C35.9809 21.3348 35.9809 23.2205 35.9809 26.9917V35.9811C30.3631 35.9811 27.5542 35.9811 25.5364 34.6328C24.6629 34.0492 23.9129 33.2992 23.3292 32.4256C21.981 30.4079 21.981 27.5989 21.981 21.9811V18.9917Z"
        fill="#D52F6D"
      />
      <path
        d="M17.981 10.9811V28.9611C17.981 31.77 17.981 33.1745 17.3068 34.1834C17.015 34.6201 16.64 34.9951 16.2032 35.287C15.1943 35.9611 13.7899 35.9611 10.981 35.9611C8.17203 35.9611 6.76757 35.9611 5.75868 35.287C5.32192 34.9951 4.94691 34.6201 4.65508 34.1834C3.98096 33.1745 3.98096 31.77 3.98096 28.9611V10.9811C3.98096 8.17216 3.98096 6.7677 4.65508 5.75881C4.94691 5.32204 5.32192 4.94704 5.75868 4.65521C6.76757 3.98109 8.17203 3.98109 10.981 3.98109C13.7899 3.98109 15.1943 3.98109 16.2032 4.65521C16.64 4.94704 17.015 5.32204 17.3068 5.75881C17.981 6.7677 17.981 8.17216 17.981 10.9811Z"
        fill="#5CB2D3"
      />
      <path
        d="M34.9728 4.00107H35.9809V8.99295C35.9809 12.7642 35.9809 14.6498 34.8094 15.8214C33.6378 16.993 31.7522 16.993 27.981 16.993H21.981C21.981 12.3314 21.981 10.0006 22.9226 8.23553C23.6694 6.83568 24.8156 5.6895 26.2154 4.9427C27.9804 4.00107 30.3112 4.00107 34.9728 4.00107Z"
        fill="#F7CF6A"
      />
      <path
        d="M49 28.8928V10.6965L53.1064 10.6837V20.7443H53.1577L57.4052 15.7653H62.2173L57.4694 21.3987L63.0515 28.8928H57.9313L53.1064 22.451V28.8928H49Z"
        fill="#16325B"
      />
      <path
        d="M71.2313 15.3033V19.1787C71.0174 19.0333 70.7394 18.952 70.3972 18.9349H70.1662C69.1225 18.9777 68.6007 19.7049 68.6007 21.1164V28.8928H64.4943V21.1164C64.4943 17.3779 66.385 15.4146 70.1662 15.2264H70.3972C70.6795 15.2349 70.9576 15.2606 71.2313 15.3033Z"
        fill="#16325B"
      />
      <path
        d="M80.194 29.3163C78.8851 29.3163 77.6489 29.0083 76.4854 28.3924C75.3219 27.7764 74.398 26.9338 73.7136 25.8644C73.0207 24.7865 72.6742 23.5802 72.6742 22.2457C72.6742 21.2447 72.8752 20.3123 73.2773 19.4482C73.6794 18.5842 74.2312 17.8356 74.9327 17.2025C75.6428 16.5609 76.4512 16.069 77.358 15.7268C78.2819 15.3846 79.2273 15.2135 80.194 15.2135C81.1692 15.2135 82.1188 15.3846 83.0428 15.7268C83.941 16.0519 84.7495 16.5395 85.4681 17.1897C86.1781 17.8399 86.7299 18.5927 87.1234 19.4482C87.5255 20.3123 87.7266 21.2447 87.7266 22.2457C87.7266 23.2637 87.5298 24.1962 87.1363 25.0431C86.7428 25.8986 86.1867 26.6557 85.4681 27.3145C84.7409 27.9646 83.9325 28.4565 83.0428 28.7902C82.1445 29.1409 81.1949 29.3163 80.194 29.3163ZM76.7677 22.2457C76.7677 23.161 77.1099 23.9481 77.7943 24.6068C78.4701 25.2655 79.27 25.5949 80.194 25.5949C81.1265 25.5949 81.9306 25.2655 82.6065 24.6068C83.2908 23.9481 83.633 23.161 83.633 22.2457C83.633 21.6468 83.4748 21.095 83.1582 20.5903C82.8417 20.0855 82.4182 19.6835 81.8878 19.384C81.366 19.0846 80.8014 18.9349 80.194 18.9349C79.6037 18.9349 79.0433 19.0846 78.5129 19.384C77.9825 19.6835 77.5591 20.0855 77.2425 20.5903C76.926 21.095 76.7677 21.6468 76.7677 22.2457Z"
        fill="#16325B"
      />
      <path
        d="M95.0723 18.9477H94.8414C93.7977 18.9905 93.2758 19.7177 93.2758 21.1292V28.8928H89.1694V21.1292C89.1694 17.3907 91.0601 15.4274 94.8414 15.2392H95.0723C98.8536 15.4274 100.744 17.3907 100.744 21.1292V28.8928H96.6379V21.1292C96.6379 19.7177 96.116 18.9905 95.0723 18.9477Z"
        fill="#16325B"
      />
      <path
        d="M109.694 29.3163C108.385 29.3163 107.149 29.0083 105.986 28.3924C104.822 27.7764 103.898 26.9338 103.214 25.8644C102.521 24.7865 102.174 23.5802 102.174 22.2457C102.174 21.2447 102.375 20.3123 102.777 19.4482C103.179 18.5842 103.731 17.8356 104.433 17.2025C105.143 16.5609 105.951 16.069 106.858 15.7268C107.782 15.3846 108.727 15.2135 109.694 15.2135C110.669 15.2135 111.619 15.3846 112.543 15.7268C113.441 16.0519 114.25 16.5395 114.968 17.1897C115.678 17.8399 116.23 18.5927 116.624 19.4482C117.026 20.3123 117.227 21.2447 117.227 22.2457C117.227 23.2637 117.03 24.1962 116.636 25.0431C116.243 25.8986 115.687 26.6557 114.968 27.3145C114.241 27.9646 113.433 28.4565 112.543 28.7902C111.645 29.1409 110.695 29.3163 109.694 29.3163ZM106.268 22.2457C106.268 23.161 106.61 23.9481 107.294 24.6068C107.97 25.2655 108.77 25.5949 109.694 25.5949C110.627 25.5949 111.431 25.2655 112.107 24.6068C112.791 23.9481 113.133 23.161 113.133 22.2457C113.133 21.6468 112.975 21.095 112.658 20.5903C112.342 20.0855 111.918 19.6835 111.388 19.384C110.866 19.0846 110.301 18.9349 109.694 18.9349C109.104 18.9349 108.543 19.0846 108.013 19.384C107.483 19.6835 107.059 20.0855 106.743 20.5903C106.426 21.095 106.268 21.6468 106.268 22.2457Z"
        fill="#16325B"
      />
      <path
        d="M120.748 24.7608C121.561 25.5735 122.553 25.9799 123.725 25.9799C124.949 25.9799 125.561 25.6206 125.561 24.902C125.561 24.5255 125.223 24.2475 124.547 24.0679C124.068 23.9566 123.306 23.7642 122.263 23.4904C121.416 23.268 120.701 22.8573 120.12 22.2585C119.546 21.6682 119.26 20.7614 119.26 19.538C119.26 18.597 119.495 17.8014 119.966 17.1512C120.445 16.501 121.065 16.022 121.826 15.714C122.605 15.3975 123.456 15.2392 124.38 15.2392C124.902 15.2392 125.432 15.3119 125.971 15.4573C126.553 15.637 127.075 15.8594 127.537 16.1246C128.041 16.4069 128.486 16.7235 128.871 17.0742L126.613 19.3199C125.954 18.8237 125.235 18.5756 124.457 18.5756C123.524 18.5756 123.058 18.8365 123.058 19.3584C123.058 19.8289 123.405 20.1583 124.098 20.3465C124.859 20.5518 125.625 20.7443 126.395 20.9239C127.242 21.1378 127.947 21.5313 128.512 22.1045C129.085 22.6862 129.372 23.6016 129.372 24.8506C129.372 25.7831 129.124 26.5873 128.627 27.2631C128.131 27.9475 127.468 28.4565 126.638 28.7902C125.809 29.1409 124.949 29.3163 124.059 29.3163C123.315 29.3163 122.643 29.2564 122.044 29.1366C121.446 29.0254 120.885 28.8372 120.363 28.572C119.824 28.3068 119.26 27.9518 118.67 27.5069L120.748 24.7608Z"
        fill="#16325B"
      />
      <path
        d="M132.367 24.7608C133.18 25.5735 134.172 25.9799 135.344 25.9799C136.568 25.9799 137.179 25.6206 137.179 24.902C137.179 24.5255 136.842 24.2475 136.166 24.0679C135.687 23.9566 134.925 23.7642 133.882 23.4904C133.035 23.268 132.32 22.8573 131.739 22.2585C131.165 21.6682 130.879 20.7614 130.879 19.538C130.879 18.597 131.114 17.8014 131.585 17.1512C132.064 16.501 132.684 16.022 133.445 15.714C134.224 15.3975 135.075 15.2392 135.999 15.2392C136.521 15.2392 137.051 15.3119 137.59 15.4573C138.172 15.637 138.694 15.8594 139.156 16.1246C139.66 16.4069 140.105 16.7235 140.49 17.0742L138.232 19.3199C137.573 18.8237 136.854 18.5756 136.076 18.5756C135.143 18.5756 134.677 18.8365 134.677 19.3584C134.677 19.8289 135.024 20.1583 135.717 20.3465C136.478 20.5518 137.244 20.7443 138.014 20.9239C138.861 21.1378 139.566 21.5313 140.131 22.1045C140.704 22.6862 140.991 23.6016 140.991 24.8506C140.991 25.7831 140.743 26.5873 140.246 27.2631C139.75 27.9475 139.087 28.4565 138.257 28.7902C137.428 29.1409 136.568 29.3163 135.678 29.3163C134.934 29.3163 134.262 29.2564 133.663 29.1366C133.065 29.0254 132.504 28.8372 131.982 28.572C131.443 28.3068 130.879 27.9518 130.288 27.5069L132.367 24.7608Z"
        fill="#16325B"
      />
    </svg>
  );
};

export default KronossLogoIcon;
