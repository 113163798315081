import styles from './SubscribeInput.module.scss';
import { FC, useState } from 'react';
import { SubscribeInputProps } from '@kronoss/components/SubscribeInput/SubscribeInput.props';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { API_URL } from '@kronoss/utils/contants';
import Toast from '@kronoss/components/Toast/Toast';
import useTranslation from 'next-translate/useTranslation';

export type SubscribeFormValues = {
  email: string;
};

const SubscribeInput: FC<SubscribeInputProps> = () => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const { t } = useTranslation('common');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<SubscribeFormValues>();

  const subscribe = handleSubmit(async (data) => {
    setIsProcessing(true);

    try {
      // Make the Axios request with the token
      await axios.post(`${API_URL}api/v1/email/subscribe`, {
        email: data.email,
      });

      // Handle successful subscription
      Toast({
        type: 'success',
        label: t('notifications.success'),
        message: t('notifications.subscribed'),
      });

      reset();
    } catch (error: any) {
      // Handle errors
      Toast({
        type: 'error',
        label: t('notifications.error'),
        message:
          error?.response?.data?.message || t('notifications.subscribeFailed'),
      });
    } finally {
      setIsProcessing(false);
    }
  });
  return (
    <form className={styles.InputContainer} onSubmit={subscribe}>
      <div className={styles.SubscribeInput}>
        <input
          {...register('email', {
            required: true,
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: t('footer:subscribeForm.email.invalidEmail'),
            },
          })}
          type="email"
          className={styles.Input}
          placeholder={t('footer:subscribeForm.email.placeholder')}
        />
        <button
          className={styles.SubscribeButton}
          type={'submit'}
          disabled={isProcessing}
          aria-label={t('footer:subscribeForm.button.title')}
        >
          {t('footer:subscribeForm.button.title')}
        </button>
      </div>
    </form>
  );
};

export default SubscribeInput;
