const XMarkIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.85339 3.02666L10.0001 8.17333L15.1201 3.05333C15.2331 2.93295 15.3694 2.83666 15.5206 2.77021C15.6718 2.70377 15.8349 2.66855 16.0001 2.66666C16.3537 2.66666 16.6928 2.80714 16.9429 3.05719C17.1929 3.30724 17.3334 3.64638 17.3334 4C17.3365 4.16347 17.3062 4.32585 17.2443 4.47717C17.1824 4.6285 17.0902 4.76558 16.9734 4.88L11.7867 10L16.9734 15.1867C17.1931 15.4017 17.322 15.6928 17.3334 16C17.3334 16.3536 17.1929 16.6928 16.9429 16.9428C16.6928 17.1929 16.3537 17.3333 16.0001 17.3333C15.8301 17.3404 15.6606 17.312 15.5022 17.25C15.3438 17.1881 15.2001 17.0938 15.0801 16.9733L10.0001 11.8267L4.86672 16.96C4.75406 17.0764 4.61947 17.1693 4.47072 17.2333C4.32197 17.2974 4.16201 17.3314 4.00005 17.3333C3.64643 17.3333 3.30729 17.1929 3.05725 16.9428C2.8072 16.6928 2.66672 16.3536 2.66672 16C2.66361 15.8365 2.69394 15.6741 2.75585 15.5228C2.81775 15.3715 2.90993 15.2344 3.02672 15.12L8.21339 10L3.02672 4.81333C2.80697 4.59834 2.67811 4.30721 2.66672 4C2.66672 3.64638 2.8072 3.30724 3.05725 3.05719C3.30729 2.80714 3.64643 2.66666 4.00005 2.66666C4.32005 2.67066 4.62672 2.8 4.85339 3.02666Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default XMarkIcon;
