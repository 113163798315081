import React, { FC } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
} from '@mui/material';
import { SelectProps } from '@kronoss/components/Select/Select.props';
import ChevronDownIcon from '@kronoss/icons/ChevronDownIcon';

const Select: FC<SelectProps> = ({
  label,
  value,
  options,
  onChange,
  name,
  error = false,
  helperText = '',
  fullWidth = true,
  variant = 'outlined',
  placeholder,
}) => {
  return (
    <FormControl fullWidth={fullWidth} variant={'standard'} error={error}>
      {label && <InputLabel>{label}</InputLabel>}
      <MuiSelect
        displayEmpty
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        variant={'standard'}
        IconComponent={ChevronDownIcon}
        sx={{
          color: '#16325B',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '0.25rem',
          borderRadius: '6px',
          border: '1px solid rgba(255, 255, 255, 0.10)',
          backgroundColor: 'transparent',
          paddingRight: '0.5rem',
          transition: '0.25s ease-in-out',
          width: 'fit-content',
          '&:after, &:before': {
            display: 'none',
          },
          '& .MuiSelect-select': {
            paddingRight: '0',
            padding: '0 0 0 0.5rem',
            display: 'flex',
            alignItems: 'center',
            height: '30px',
            gap: '0.5rem',
          },
          '& .MuiInput-input': {
            paddingRight: '0!important',
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              boxShadow: '-3px 3px 10.8px 0px rgba(162, 169, 176, 0.30)',
              backgroundColor: '#FBFDFE',
              padding: '0.75rem 0',
              borderRadius: '8px',
              '& .MuiList-root': {
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                alignItems: 'center',
                padding: 0,
              },
              '& .MuiMenuItem-root': {
                color: '#16325B',
                padding: '0.25rem 1rem',
                width: '100%',
                display: 'flex',
                gap: '0.5rem',
                alignItems: 'center',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                minHeight: 'fit-content',
              },
              '& .MuiMenuItem-root:hover': {
                backgroundColor: '#DEE8F7!important',
              },
              '& .Mui-selected': {
                backgroundColor: '#DEE8F7!important',
              },
              '& .Mui-selected:hover': {
                backgroundColor: '#DEE8F7!important',
              },
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
