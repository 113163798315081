import { FC } from 'react';
import { ButtonProps } from '@kronoss/components/Button/Button.props';
import { Button as MuiButton } from '@mui/material';
import styles from './Button.module.scss';
import cn from 'classnames';

const Button: FC<ButtonProps> = ({ children, className, ...rest }) => {
  return (
    <MuiButton
      {...rest}
      className={cn(styles.Button, className)}
      sx={{
        textTransform: 'initial',
        color: '#FBFDFE',
        borderRadius: '0.75rem',
        backgroundColor: '#2A5FAC',
        padding: '0.625rem 1rem',
        fontSize: '14px',
      }}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
