import React from 'react';
import { ToastComponentProps } from './ToastComponent.props';
import styles from './ToastComponent.module.scss';
import cn from 'classnames';
import InfoIcon from '@kronoss/icons/InfoIcon';

const ToastComponent = ({ type, message, label }: ToastComponentProps) => {
  return (
    <div className={styles.Toast}>
      <div className={styles.InfoWrapper}>
        <div className={cn(styles.Icon, styles[type])}>
          <InfoIcon />
        </div>
        <div className={styles.MessageWrapper}>
          <h3 className={cn(styles.Label)}>{label}</h3>
          <h4 className={styles.Message}>{message}</h4>
        </div>
      </div>
    </div>
  );
};

export default ToastComponent;
