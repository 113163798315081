const KronossWhiteLogoIcon = () => {
  return (
    <svg
      width="141"
      height="40"
      viewBox="0 0 141 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="6.77057" fill="#F3F4F6" />
      <path
        d="M21.981 18.9916H27.981C31.7522 18.9916 33.6378 18.9916 34.8094 20.1631C35.9809 21.3347 35.9809 23.2203 35.9809 26.9916V35.9809C30.3631 35.9809 27.5542 35.9809 25.5364 34.6327C24.6629 34.049 23.9129 33.299 23.3292 32.4255C21.981 30.4077 21.981 27.5988 21.981 21.9809V18.9916Z"
        fill="#D52F6D"
      />
      <path
        d="M17.981 10.981V28.961C17.981 31.7699 17.981 33.1743 17.3068 34.1832C17.015 34.62 16.64 34.995 16.2032 35.2868C15.1943 35.961 13.7899 35.961 10.981 35.961C8.17203 35.961 6.76757 35.961 5.75868 35.2868C5.32192 34.995 4.94691 34.62 4.65508 34.1832C3.98096 33.1743 3.98096 31.7699 3.98096 28.961V10.981C3.98096 8.17203 3.98096 6.76757 4.65508 5.75868C4.94691 5.32192 5.32192 4.94691 5.75868 4.65508C6.76757 3.98096 8.17203 3.98096 10.981 3.98096C13.7899 3.98096 15.1943 3.98096 16.2032 4.65508C16.64 4.94691 17.015 5.32192 17.3068 5.75868C17.981 6.76757 17.981 8.17203 17.981 10.981Z"
        fill="#5CB2D3"
      />
      <path
        d="M34.9728 4.00094H35.9809V8.99282C35.9809 12.7641 35.9809 14.6497 34.8094 15.8212C33.6378 16.9928 31.7522 16.9928 27.981 16.9928H21.981C21.981 12.3312 21.981 10.0004 22.9226 8.2354C23.6694 6.83555 24.8156 5.68937 26.2154 4.94257C27.9804 4.00094 30.3112 4.00094 34.9728 4.00094Z"
        fill="#F7CF6A"
      />
      <path
        d="M49 28.8927V10.6964L53.1064 10.6836V20.7442H53.1577L57.4052 15.7652H62.2173L57.4694 21.3986L63.0515 28.8927H57.9313L53.1064 22.4509V28.8927H49Z"
        fill="#DEE8F7"
      />
      <path
        d="M71.2313 15.3032V19.1786C71.0174 19.0332 70.7394 18.9519 70.3972 18.9348H70.1662C69.1225 18.9776 68.6007 19.7048 68.6007 21.1163V28.8927H64.4943V21.1163C64.4943 17.3778 66.385 15.4145 70.1662 15.2263H70.3972C70.6795 15.2348 70.9576 15.2605 71.2313 15.3032Z"
        fill="#DEE8F7"
      />
      <path
        d="M80.194 29.3162C78.8851 29.3162 77.6489 29.0082 76.4854 28.3923C75.3219 27.7763 74.398 26.9337 73.7136 25.8643C73.0207 24.7864 72.6742 23.5801 72.6742 22.2456C72.6742 21.2446 72.8752 20.3122 73.2773 19.4481C73.6794 18.5841 74.2312 17.8355 74.9327 17.2024C75.6428 16.5608 76.4512 16.0689 77.358 15.7267C78.2819 15.3845 79.2273 15.2134 80.194 15.2134C81.1692 15.2134 82.1188 15.3845 83.0428 15.7267C83.941 16.0518 84.7495 16.5394 85.4681 17.1896C86.1781 17.8398 86.7299 18.5926 87.1234 19.4481C87.5255 20.3122 87.7266 21.2446 87.7266 22.2456C87.7266 23.2636 87.5298 24.1961 87.1363 25.043C86.7428 25.8985 86.1867 26.6556 85.4681 27.3144C84.7409 27.9645 83.9325 28.4564 83.0428 28.7901C82.1445 29.1408 81.1949 29.3162 80.194 29.3162ZM76.7677 22.2456C76.7677 23.1609 77.1099 23.948 77.7943 24.6067C78.4701 25.2654 79.27 25.5948 80.194 25.5948C81.1265 25.5948 81.9306 25.2654 82.6065 24.6067C83.2908 23.948 83.633 23.1609 83.633 22.2456C83.633 21.6467 83.4748 21.0949 83.1582 20.5902C82.8417 20.0854 82.4182 19.6834 81.8878 19.3839C81.366 19.0845 80.8014 18.9348 80.194 18.9348C79.6037 18.9348 79.0433 19.0845 78.5129 19.3839C77.9825 19.6834 77.5591 20.0854 77.2425 20.5902C76.926 21.0949 76.7677 21.6467 76.7677 22.2456Z"
        fill="#DEE8F7"
      />
      <path
        d="M95.0723 18.9476H94.8414C93.7977 18.9904 93.2758 19.7176 93.2758 21.1291V28.8927H89.1694V21.1291C89.1694 17.3906 91.0601 15.4273 94.8414 15.2391H95.0723C98.8536 15.4273 100.744 17.3906 100.744 21.1291V28.8927H96.6379V21.1291C96.6379 19.7176 96.116 18.9904 95.0723 18.9476Z"
        fill="#DEE8F7"
      />
      <path
        d="M109.694 29.3162C108.385 29.3162 107.149 29.0082 105.986 28.3923C104.822 27.7763 103.898 26.9337 103.214 25.8643C102.521 24.7864 102.174 23.5801 102.174 22.2456C102.174 21.2446 102.375 20.3122 102.777 19.4481C103.179 18.5841 103.731 17.8355 104.433 17.2024C105.143 16.5608 105.951 16.0689 106.858 15.7267C107.782 15.3845 108.727 15.2134 109.694 15.2134C110.669 15.2134 111.619 15.3845 112.543 15.7267C113.441 16.0518 114.25 16.5394 114.968 17.1896C115.678 17.8398 116.23 18.5926 116.624 19.4481C117.026 20.3122 117.227 21.2446 117.227 22.2456C117.227 23.2636 117.03 24.1961 116.636 25.043C116.243 25.8985 115.687 26.6556 114.968 27.3144C114.241 27.9645 113.433 28.4564 112.543 28.7901C111.645 29.1408 110.695 29.3162 109.694 29.3162ZM106.268 22.2456C106.268 23.1609 106.61 23.948 107.294 24.6067C107.97 25.2654 108.77 25.5948 109.694 25.5948C110.627 25.5948 111.431 25.2654 112.107 24.6067C112.791 23.948 113.133 23.1609 113.133 22.2456C113.133 21.6467 112.975 21.0949 112.658 20.5902C112.342 20.0854 111.918 19.6834 111.388 19.3839C110.866 19.0845 110.301 18.9348 109.694 18.9348C109.104 18.9348 108.543 19.0845 108.013 19.3839C107.483 19.6834 107.059 20.0854 106.743 20.5902C106.426 21.0949 106.268 21.6467 106.268 22.2456Z"
        fill="#DEE8F7"
      />
      <path
        d="M120.748 24.7607C121.561 25.5734 122.553 25.9798 123.725 25.9798C124.949 25.9798 125.561 25.6205 125.561 24.9019C125.561 24.5254 125.223 24.2474 124.547 24.0678C124.068 23.9565 123.306 23.7641 122.263 23.4903C121.416 23.2679 120.701 22.8572 120.12 22.2584C119.546 21.6681 119.26 20.7613 119.26 19.5379C119.26 18.5969 119.495 17.8013 119.966 17.1511C120.445 16.5009 121.065 16.0219 121.826 15.7139C122.605 15.3974 123.456 15.2391 124.38 15.2391C124.902 15.2391 125.432 15.3118 125.971 15.4572C126.553 15.6369 127.075 15.8593 127.537 16.1245C128.041 16.4068 128.486 16.7234 128.871 17.0741L126.613 19.3198C125.954 18.8236 125.235 18.5755 124.457 18.5755C123.524 18.5755 123.058 18.8364 123.058 19.3583C123.058 19.8288 123.405 20.1582 124.098 20.3464C124.859 20.5517 125.625 20.7442 126.395 20.9238C127.242 21.1377 127.947 21.5312 128.512 22.1044C129.085 22.6861 129.372 23.6015 129.372 24.8505C129.372 25.783 129.124 26.5872 128.627 27.263C128.131 27.9474 127.468 28.4564 126.638 28.7901C125.809 29.1408 124.949 29.3162 124.059 29.3162C123.315 29.3162 122.643 29.2563 122.044 29.1365C121.446 29.0253 120.885 28.8371 120.363 28.5719C119.824 28.3067 119.26 27.9517 118.67 27.5068L120.748 24.7607Z"
        fill="#DEE8F7"
      />
      <path
        d="M132.367 24.7607C133.18 25.5734 134.172 25.9798 135.344 25.9798C136.568 25.9798 137.179 25.6205 137.179 24.9019C137.179 24.5254 136.842 24.2474 136.166 24.0678C135.687 23.9565 134.925 23.7641 133.882 23.4903C133.035 23.2679 132.32 22.8572 131.739 22.2584C131.165 21.6681 130.879 20.7613 130.879 19.5379C130.879 18.5969 131.114 17.8013 131.585 17.1511C132.064 16.5009 132.684 16.0219 133.445 15.7139C134.224 15.3974 135.075 15.2391 135.999 15.2391C136.521 15.2391 137.051 15.3118 137.59 15.4572C138.172 15.6369 138.694 15.8593 139.156 16.1245C139.66 16.4068 140.105 16.7234 140.49 17.0741L138.232 19.3198C137.573 18.8236 136.854 18.5755 136.076 18.5755C135.143 18.5755 134.677 18.8364 134.677 19.3583C134.677 19.8288 135.024 20.1582 135.717 20.3464C136.478 20.5517 137.244 20.7442 138.014 20.9238C138.861 21.1377 139.566 21.5312 140.131 22.1044C140.704 22.6861 140.991 23.6015 140.991 24.8505C140.991 25.783 140.743 26.5872 140.246 27.263C139.75 27.9474 139.087 28.4564 138.257 28.7901C137.428 29.1408 136.568 29.3162 135.678 29.3162C134.934 29.3162 134.262 29.2563 133.663 29.1365C133.065 29.0253 132.504 28.8371 131.982 28.5719C131.443 28.3067 130.879 27.9517 130.288 27.5068L132.367 24.7607Z"
        fill="#DEE8F7"
      />
    </svg>
  );
};

export default KronossWhiteLogoIcon;
